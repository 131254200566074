<template>
  <v-dialog v-model="showDialog" persistent :max-width="maxWidth">
    <v-card>
      <v-row v-if="!isFinalSelectDialog" class="mr-0" style="align-items: center;">
        <v-col cols="8">
          <v-card-title
            v-if="title !== null"
            class="headline font-weight-bold py-0"
          >
            {{ title }}
          </v-card-title>
        </v-col>
        <v-col cols="4">
          <v-btn
            rounded
            class="font-weight-black float-right"
            color="back"
            width="5vw"
            max-width="170px"
            style="font-size:15px !important"
            dark
            @click="onClickNegativeButton"
          >
            {{ negativeButtonTitle }}
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="py-0">
          <v-card-subtitle v-if="subTitle !== null" class="py-2">{{
            subTitle
          }}</v-card-subtitle>
        </v-col>
      </v-row>
      <v-row v-if="plans !== null" class="ml-0 mr-0">
        <v-col>
          <v-container>
            <table class="plan-list-table">
              <tbody>
                <tr v-for="plan in plans" :key="plan.id">
                  <td :key="plan.index"  @click="onClickPositiveButton(plan)" >{{ plan.planName }}</td>
                  <td v-if="plan.comment" @click="planExplanationDialog(plan)"><div class="plan-explanation ml-2"></div></td>
                </tr>
              </tbody>
            </table>
          </v-container>
        </v-col>
      </v-row>

      <!-- プラン説明モーダル -->
      <ItemExplanationDialog
        :maxWidth="320"
        :showDialog="showPlanExplanationDialog"
        :title="explanationPlan.planName"
        :text="explanationPlan.comment"
        :negativeButtonTitle="$t('button.close')"
        :onClickNegativeButton="planExplanationDialog"
      />

      <v-card-text
        v-if="text !== null"
        :class="isFinalSelectDialog ? textCenterStyle : textLeftStyle"
        ><p
          v-for="(message, index) in splitMessageLine(text)"
          :key="index"
          class="my-0"
        >
          {{ message }}
        </p></v-card-text
      >
      <v-card-text
        v-if="subText !== null"
        class="font-weight-black text-center"
        >{{ subText }}</v-card-text
      >
      <template v-if="!isFinalSelectDialog">
        <template v-if="positiveButtonTitle !== null">
          <v-card-actions class="justify-center pb-5">
            <v-btn
              v-if="positiveButtonTitle !== null"
              rounded
              class="title font-weight-black"
              color="next"
              width="50vw"
              max-width="200px"
              style="font-size:15px !important"
              dark
              @click="onClickPositiveButton(planDetail)"
              >{{ positiveButtonTitle }}</v-btn
            >
          </v-card-actions>
        </template>
      </template>
      <template v-if="isFinalSelectDialog">
        <v-card-actions class="justify-center pb-5">
          <v-btn
            rounded
            class="title font-weight-black mr-4"
            color="back"
            width="30vw"
            max-width="120px"
            style="font-size:15px !important"
            dark
            @click="onClickNegativeButton"
            >{{ negativeButtonTitle }}</v-btn
          >
          <v-btn
            :disabled="disabled"
            rounded
            class="title font-weight-black"
            color="next"
            width="30vw"
            max-width="120px"
            style="font-size:15px !important"
            dark
            @click="onClickPositiveButton()"
            >{{ positiveButtonTitle }}</v-btn
          >
        </v-card-actions>
      </template>
    </v-card>
  </v-dialog>
</template>

<script>
import { splitMessageLine } from '@/lib/commonUtil';
import ItemExplanationDialog from '@/components/organisms/contractor/ItemExplanationDialog.vue';

export default {
  name: 'SelectPlanDialog',
  components: {
    ItemExplanationDialog,
  },
  props: {
    maxWidth: Number,
    title: String,
    subTitle: String,
    text: String,
    subText: String,
    plans: Array[Object],
    plan: String,
    planDetail: Object,
    negativeButtonTitle: String,
    positiveButtonTitle: String,
    onClickNegativeButton: Function,
    onClickPositiveButton: Function,
    showDialog: Boolean,
    disabled: Boolean,
    isFinalSelectDialog: Boolean,
  },
  data() {
    return {
      inset: false,
      textCenterStyle: ['font-weight-black', 'text-center', 'pt-5'],
      textLeftStyle: ['font-weight-black', 'text-left'],
      showPlanExplanationDialog: false,
      explanationPlan: [],
    };
  },
  methods: {
    splitMessageLine(message) {
      return splitMessageLine(message);
    },
    planExplanationDialog(targetExplanationPlan) {
      this.showPlanExplanationDialog = !this.showPlanExplanationDialog;
      this.explanationPlan = targetExplanationPlan;
    },
  },
};
</script>
<style>
.plan-list-table {
  border-collapse: collapse;
  cursor: pointer;
}

.plan-list-table tr td:nth-of-type(1) {
  width: 250px;
  border: 1px solid #f2f2f2;
  padding: 10px;
  box-shadow: 0 0 3px 0 #f2f2f2;
}

.plan-list-table tr td:nth-of-type(1):hover {
  background: #f2f2f2;
}
</style>
