<template>
  <v-container fluid>
    <v-layout column fill-height wrap>
      <v-container
        style="width: 100%; max-height=90vh; overflow: hidden; pl-0"
        class="mx-auto"
      >
        <v-row>
          <v-col cols="12">
            <ContractNumber
                :contract="contract"
                v-if="pageIndex === 1"
            />
          </v-col>
        </v-row>
        <StepFirstContent
          v-if="pageIndex === 1"
          :contract="contract"
        />
        <StepSecondContent
          v-if="pageIndex === 2"
          :contract="contract"
        />
        <StepThirdContent
          v-if="pageIndex === 3"
          :contract="contract"
          :planChoices="planChoices"
          :finalReviewFlag="finalReviewFlag"
        />
        <StepFourthContent
          v-if="pageIndex === 4"
          :contract="contract"
        />

        <v-row justify="center">
          <v-col
            v-for="n in 2"
            :key="n"
            width="30vw"
            max-width="170px"
            class="pa-0"
            style="text-align:center"
          >
            <template v-if="n == 1" class="pa-0">
              <!-- 戻るボタン -->
              <v-btn
                class="font-weight-black mx-auto"
                align="center"
                @click="onBackClicked"
                width="30vw"
                max-width="170px"
                color="back"
                style="font-size:15px"
                dark
                rounded
                >{{ $t('button.back') }}</v-btn
              >
            </template>
            <!-- 確認済みボタン -->
            <template v-if="n == 2" class="pa-0">
              <!-- プラン選択ボタン -->
              <template v-if="choosePlan">
                <template>
                  <v-btn
                    class="font-weight-black mx-auto"
                    align="center"
                    width="30vw"
                    max-width="170px"
                    color="next"
                    style="font-size:15px"
                    dark
                    @click="openPlansDialog()"
                    rounded
                    >{{ $t('button.selectPlan') }}
                  </v-btn>
                </template>
              </template>
              <template v-else>
                <v-btn
                  class="font-weight-black mx-auto"
                  align="center"
                  width="30vw"
                  max-width="170px"
                  color="next"
                  style="font-size:15px"
                  :dark="isShowContract"
                  :disabled="!isShowContract"
                  rounded
                  @click="goNextPage"
                  >{{ $t('button.reviewed') }}</v-btn
                >
              </template>
            </template>
          </v-col>
        </v-row>
        <!-- プラン一覧 -->
        <SelectPlanDialog
          :maxWidth="320"
          :showDialog="showPlansDialog"
          :isFinalSelectDialog="false"
          :plans="planChoices"
          :plan="null"
          :planDetail="null"
          :title="$t('button.selectPlan')"
          :subTitle="$t('description.reviewContract.explanation2')"
          :text="$t('description.reviewContract.explanation1')"
          :subText="null"
          :negativeButtonTitle="$t('button.close')"
          :positiveButtonTitle="null"
          :onClickNegativeButton="closePlansDialog"
          :onClickPositiveButton="openPlanDetailDialog"
        />
        <!-- プラン詳細 -->
        <SelectPlanDialog
          :maxWidth="320"
          :showDialog="showPlanDetailDialog"
          :isFinalSelectDialog="false"
          :plans="null"
          :plan="null"
          :planDetail="planDetail"
          :title="planDetail.planName"
          :subTitle="null"
          :text="null"
          :subText="null"
          :negativeButtonTitle="$t('button.return')"
          :positiveButtonTitle="$t('button.selectThisPlan')"
          :onClickNegativeButton="closePlanDetailDialog"
          :onClickPositiveButton="openPlanDialog"
        />
        <!-- プラン選択確認 -->
        <SelectPlanDialog
          :maxWidth="320"
          :showDialog="showSelectPlanDialog"
          :isFinalSelectDialog="true"
          :plans="null"
          :plan="nowSelectedPlan.planName"
          :planDetail="planDetail"
          :title="null"
          :subTitle="null"
          :text="planText"
          :subText="$t('description.selectPlanDialog.explanation2')"
          :negativeButtonTitle="$t('button.return')"
          :positiveButtonTitle="$t('button.reviewed')"
          :onClickNegativeButton="closeSelectPlanDialog"
          :onClickPositiveButton="selectPlan"
        />
      </v-container>
    </v-layout>
  </v-container>
</template>
<script>
import SelectPlanDialog from '../../components/organisms/contractor/SelectPlanDialog.vue';
import { mapActions, mapGetters } from 'vuex';
import { getPlans } from '@/apis/contractor/plans';
import { getContract } from '@/apis/contractor/contracts';
import { reviewContractStepPageSum } from '@/lib/const';
import ContractNumber from '@/components/molecules/contractor/ContractNumber.vue';
import StepFirstContent from '@/pages/contractor/reviewContractTemplates/StepFirstContent.vue';
import StepSecondContent from '@/pages/contractor/reviewContractTemplates/StepSecondContent.vue';
import StepThirdContent from '@/pages/contractor/reviewContractTemplates/StepThirdContent.vue';
import StepFourthContent from '@/pages/contractor/reviewContractTemplates/StepFourthContent.vue';

export default {
  props: {
    stepPoint: Number,
  },
  components: {
    SelectPlanDialog,
    ContractNumber,
    StepFirstContent,
    StepSecondContent,
    StepThirdContent,
    StepFourthContent,
  },
  data: () => ({
    contract: {},
    choosePlan: false,
    planChoices: [],
    showSelectPlanDialog: false,
    showPlansDialog: false,
    showPlanDetailDialog: false,
    planDetail: {},
    nowSelectedPlan: { planName: '' },
    planText: '',
    pageIndex: 0,
    isShowContract: false,
    finalReviewFlag: false,
  }),

  async mounted() {
    this.contract = await getContract().catch(() => {});
    this.setPageSum(reviewContractStepPageSum);
    this.pageIndex = Number(this.$route.params.pageNumber);

    const response = await getPlans();
    if (response instanceof Error) {
      this.$router.replace('/Error');
      return;
    }
    if (response) {
      const plans = response.filter(plan => {
        return plan.startDate !== null && plan.endDate !== null && plan.insurancePeriodText !== null
      })

      if (plans.length < 1 || plans.length > 3) throw new Error('invalid plans count')

      for (let i = 0; i < plans.length; i++) {
        plans[i].key = 'planTab' + (i + 1);
      }
      this.$set(this, 'planChoices', plans);
      this.setPlans(plans);
    }

    // 3ページ目: プラン選択ボタンを表示する
    this.$set(this, 'choosePlan', this.pageIndex === 3);

    this.$set(this, 'isShowContract', true);
  },
  methods: {
    ...mapGetters('contract', [
      'needUpload',
      'selectedPlan',
      'pageSum',
      'pendingStep',
      'pendingApplicationFormsPage',
    ]),
    ...mapActions('contract', [
      'setSelectedPlan',
      'setPlans',
      'setNeedUpload',
      'setPageSum',
      'setPendingStep',
      'setPendingApplicationFormsPage',
    ]),
    openPlanDialog(plan) {
      this.planText =
        plan.planName + this.$t('description.selectPlanDialog.explanation1');
      this.nowSelectedPlan = plan;
      this.showSelectPlanDialog = true;
    },
    selectPlan() {
      this.setSelectedPlan(this.nowSelectedPlan);
      this.closeSelectPlanDialog();
      this.closePlanDetailDialog();
      this.closePlansDialog();
      this.goNextPage();
    },
    closeSelectPlanDialog() {
      this.showSelectPlanDialog = false;
    },
    openPlansDialog() {
      this.showPlansDialog = true;
    },
    closePlansDialog() {
      this.showPlansDialog = false;
    },
    openPlanDetailDialog(plan) {
      this.planDetail = plan;
      this.showPlanDetailDialog = true;
    },
    closePlanDetailDialog() {
      this.showPlanDetailDialog = false;
    },
    goNextPage() {
      this.$set(this, 'isShowContract', false);
      let nextPath;
      const nextPage = this.pageIndex + 1;
      if (nextPage > this.pageSum()) {
        nextPath = this.needUpload()
          ? '/check_need_upload#upload_required_documents'
          : '/explan_important_matters';
        this.setNeedUpload(this.needUpload());

        const nextStep = this.stepPoint + 1;
        // 操作内容によって遷移先が異なるため確認ステップを強制的に書き換える
        this.setPendingStep(nextStep);
      } else {
        if (this.pendingApplicationFormsPage() < nextPage)
          this.setPendingApplicationFormsPage(nextPage);
        nextPath = `/review_contract/${nextPage}`;
      }
      window.location.href = nextPath;
    },
    onBackClicked() {
      const backPageNumber = --this.$route.params.pageNumber;
      const backPath =
        backPageNumber > 0
          ? `/review_contract/${backPageNumber}`
          : '/base_info';
      window.location.href = backPath;
    },
  },
};
</script>
<style>
.base_td_category_text {
  width: 33%;
  background: #d5ebfe;
}
</style>
