var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-layout",
        { attrs: { column: "", "fill-height": "", wrap: "" } },
        [
          _c(
            "v-container",
            {
              staticClass: "mx-auto",
              staticStyle: { width: "100%", overflow: "hidden" },
            },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _vm.pageIndex === 1
                        ? _c("ContractNumber", {
                            attrs: { contract: _vm.contract },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.pageIndex === 1
                ? _c("StepFirstContent", { attrs: { contract: _vm.contract } })
                : _vm._e(),
              _vm.pageIndex === 2
                ? _c("StepSecondContent", { attrs: { contract: _vm.contract } })
                : _vm._e(),
              _vm.pageIndex === 3
                ? _c("StepThirdContent", {
                    attrs: {
                      contract: _vm.contract,
                      planChoices: _vm.planChoices,
                      finalReviewFlag: _vm.finalReviewFlag,
                    },
                  })
                : _vm._e(),
              _vm.pageIndex === 4
                ? _c("StepFourthContent", { attrs: { contract: _vm.contract } })
                : _vm._e(),
              _c(
                "v-row",
                { attrs: { justify: "center" } },
                _vm._l(2, function (n) {
                  return _c(
                    "v-col",
                    {
                      key: n,
                      staticClass: "pa-0",
                      staticStyle: { "text-align": "center" },
                      attrs: { width: "30vw", "max-width": "170px" },
                    },
                    [
                      n == 1
                        ? [
                            _c(
                              "v-btn",
                              {
                                staticClass: "font-weight-black mx-auto",
                                staticStyle: { "font-size": "15px" },
                                attrs: {
                                  align: "center",
                                  width: "30vw",
                                  "max-width": "170px",
                                  color: "back",
                                  dark: "",
                                  rounded: "",
                                },
                                on: { click: _vm.onBackClicked },
                              },
                              [_vm._v(_vm._s(_vm.$t("button.back")))]
                            ),
                          ]
                        : _vm._e(),
                      n == 2
                        ? [
                            _vm.choosePlan
                              ? [
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass:
                                          "font-weight-black mx-auto",
                                        staticStyle: { "font-size": "15px" },
                                        attrs: {
                                          align: "center",
                                          width: "30vw",
                                          "max-width": "170px",
                                          color: "next",
                                          dark: "",
                                          rounded: "",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.openPlansDialog()
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("button.selectPlan")) +
                                            "\n                "
                                        ),
                                      ]
                                    ),
                                  ],
                                ]
                              : [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "font-weight-black mx-auto",
                                      staticStyle: { "font-size": "15px" },
                                      attrs: {
                                        align: "center",
                                        width: "30vw",
                                        "max-width": "170px",
                                        color: "next",
                                        dark: _vm.isShowContract,
                                        disabled: !_vm.isShowContract,
                                        rounded: "",
                                      },
                                      on: { click: _vm.goNextPage },
                                    },
                                    [_vm._v(_vm._s(_vm.$t("button.reviewed")))]
                                  ),
                                ],
                          ]
                        : _vm._e(),
                    ],
                    2
                  )
                }),
                1
              ),
              _c("SelectPlanDialog", {
                attrs: {
                  maxWidth: 320,
                  showDialog: _vm.showPlansDialog,
                  isFinalSelectDialog: false,
                  plans: _vm.planChoices,
                  plan: null,
                  planDetail: null,
                  title: _vm.$t("button.selectPlan"),
                  subTitle: _vm.$t("description.reviewContract.explanation2"),
                  text: _vm.$t("description.reviewContract.explanation1"),
                  subText: null,
                  negativeButtonTitle: _vm.$t("button.close"),
                  positiveButtonTitle: null,
                  onClickNegativeButton: _vm.closePlansDialog,
                  onClickPositiveButton: _vm.openPlanDetailDialog,
                },
              }),
              _c("SelectPlanDialog", {
                attrs: {
                  maxWidth: 320,
                  showDialog: _vm.showPlanDetailDialog,
                  isFinalSelectDialog: false,
                  plans: null,
                  plan: null,
                  planDetail: _vm.planDetail,
                  title: _vm.planDetail.planName,
                  subTitle: null,
                  text: null,
                  subText: null,
                  negativeButtonTitle: _vm.$t("button.return"),
                  positiveButtonTitle: _vm.$t("button.selectThisPlan"),
                  onClickNegativeButton: _vm.closePlanDetailDialog,
                  onClickPositiveButton: _vm.openPlanDialog,
                },
              }),
              _c("SelectPlanDialog", {
                attrs: {
                  maxWidth: 320,
                  showDialog: _vm.showSelectPlanDialog,
                  isFinalSelectDialog: true,
                  plans: null,
                  plan: _vm.nowSelectedPlan.planName,
                  planDetail: _vm.planDetail,
                  title: null,
                  subTitle: null,
                  text: _vm.planText,
                  subText: _vm.$t("description.selectPlanDialog.explanation2"),
                  negativeButtonTitle: _vm.$t("button.return"),
                  positiveButtonTitle: _vm.$t("button.reviewed"),
                  onClickNegativeButton: _vm.closeSelectPlanDialog,
                  onClickPositiveButton: _vm.selectPlan,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }